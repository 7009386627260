import * as React from "react";
import { useStoreActions } from "../../hooks";

import { generatePlainText, generateHash } from "../../services/crypto";
import { setUserToken, setPortalViewer } from "../../services/auth";
import { FaSpinner } from "react-icons/fa";

const { useEffect } = React;

export default function PortalAdminRedirect() {
  const url = new URL(window.location.href);

  const cipherToken = decodeURIComponent(url.searchParams.get("accessToken"));
  const tokenHash = decodeURIComponent(url.searchParams.get("tokenHash"));
  const cipherAuth = decodeURIComponent(url.searchParams.get("auth"));
  const authHash = decodeURIComponent(url.searchParams.get("authHash"));
  const viewerCipher = decodeURIComponent(url.searchParams.get("viewer"));
  const viewerHash = decodeURIComponent(url.searchParams.get("viewerHash"));
  const counter = url.searchParams.get("counter");

  const setAuthObject = useStoreActions(
    (actions) => actions.authStore.setAuthObject
  );

  const setAuthUser = useStoreActions(
    (actions) => actions.authStore.setAuthUser
  );

  const initDetails = async () => {
    localStorage.clear();

    const viewer = generatePlainText(viewerCipher);
    const accessToken = generatePlainText(cipherToken);
    const authUser = generatePlainText(cipherAuth);

    const vHash = generateHash(viewer);
    const tHash = generateHash(accessToken);
    const aHash = generateHash(authUser);

    if (tHash !== tokenHash && aHash !== authHash && vHash === viewerHash) {
      window.location = "/auth";
    } else {
      setUserToken(accessToken, () => {});

      setAuthUser(JSON.parse(authUser));

      const user = JSON.parse(authUser);

      const v = JSON.parse(viewer);

      setPortalViewer(v);

      if (user.stripe_id === "empty" || user.stripe_id === "null") {
        setAuthObject({
          userId: Number(user.id),
          stripeId: null,
          accessToken: accessToken,
        });
      } else {
        setAuthObject({
          userId: Number(user.id),
          stripeId: user.stripe_id,
          accessToken: accessToken,
        });
      }
    }
  };

  useEffect(() => {
    initDetails().then(() => {
      window.location.assign("/");
    });
  }, [counter]);

  return (
    <div
      className={`w-full h-screen flex flex-col justify-center items-center`}
    >
      <FaSpinner className={`animate-spin text-qipGreen font-bold text-6xl`} />

      <div>Please wait...</div>
    </div>
  );
}
