import React, { useEffect, Suspense } from "react";
import {
  BrowserRouter,
  Switch,
  useRouteMatch,
  Route,
  Redirect,
} from "react-router-dom";
import Layout from "../../components/Layout";
import { AppContext, Routes } from "../../context/App";

import LogoutRedirect from "../../pages/LogoutRedirect";

const LoggedOutPages: React.FC<any> = (): JSX.Element => {
  const match = useRouteMatch();
  return (
    <BrowserRouter>
      <AppContext.Provider value={{ route: Routes.DASHBOARD }}>
        <Switch>
          <Route
            exact
            path={`/`}
            render={(props) => {
              return (
                <Layout
                  route={Routes.DASHBOARD}
                  Children={LogoutRedirect}
                  {...props}
                />
              );
            }}
          />

          <Route
            path={`${match.path}`}
            render={() => {
              return <Redirect to={`/`} />;
            }}
          />
        </Switch>
      </AppContext.Provider>
    </BrowserRouter>
  );
};

export default LoggedOutPages;
