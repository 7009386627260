import * as Crypto from "crypto-js";

// AES / CTR Mode
// 128bits CipherText || 10 rounds encryption
export const generateCipherText = (
  message: string,
  iv: Crypto.lib.WordArray,
  salt: Crypto.lib.WordArray
) => {
  // default nonce

  const key = Crypto.PBKDF2(
    process.env.REACT_APP_PASSPHRASE
      ? process.env.REACT_APP_PASSPHRASE
      : "nkbxfWAJzWy6tCC3DpluJg==",
    salt,
    {
      keySize: 8,
      iterations: 1024,
    }
  );

  const ciphertext = Crypto.AES.encrypt(message, key, {
    iv,
  });

  return salt.toString() + iv.toString() + ciphertext.toString();
};

export const generatePlainText = (ciphertext: string) => {
  const salt = Crypto.enc.Hex.parse(ciphertext.substr(0, 32));
  const iv = Crypto.enc.Hex.parse(ciphertext.substr(32, 32));

  const cipher = ciphertext.substring(64);

  //
  const key = Crypto.PBKDF2(
    process.env.REACT_APP_PASSPHRASE
      ? process.env.REACT_APP_PASSPHRASE
      : "nkbxfWAJzWy6tCC3DpluJg==",
    salt,
    {
      keySize: 8,
      iterations: 1024,
    }
  );

  const deciphered = Crypto.AES.decrypt(cipher, key, {
    iv,
  });

  return deciphered.toString(Crypto.enc.Utf8);
};

export const generateHash = (message: string) => {
  return Crypto.SHA256(message).toString(Crypto.enc.Base64);
};
