import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { isUserLoggedIn } from "../../services/auth";

const Footer: React.FC<any> = (): JSX.Element => {
  return (
    <div className="portalFooter">
      <div className={`footerTop ${isUserLoggedIn() ? "" : "hidden"}`}>
        <div className="footerRow">
          <h3 className="text-center">Holistic products shortcuts</h3>

          <ul className="products">
            <li className="product commingSoon text-center">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                className="svg-inline--fa fa-times fa-w-11"
                role="img"
                width=" 14"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
              <span className="block">CCS Software</span>
              <a href="javascript:void(0)" className="inline-block">
                COMING SOON
              </a>
            </li>

            <li className="product visit text-center">
              <FaCheck size={14} />
              <span className="block">Products & Events</span>
              <a
                href="https://holisticmanagement.net.au/resources/"
                className="inline-block"
                target="_blank"
                rel="noreferrer"
              >
                VISIT
              </a>
            </li>

            <li className="product commingSoon text-center">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                className="svg-inline--fa fa-times fa-w-11"
                role="img"
                width=" 14"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
              <span className="block">HR Software</span>
              <a href="javascript:void(0)" className="inline-block">
                COMING SOON
              </a>
            </li>

            <li className="product commingSoon text-center">
              <FaTimes size={20} />
              <span className="block">Resources</span>
              <a href="javascript:void(0)" className="inline-block">
                COMING SOON
              </a>
            </li>

            <li className="product subscribed active text-center">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check"
                className="svg-inline--fa fa-check fa-w-16"
                role="img"
                width=" 14"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <span className="block">HMC Portal</span>
              <a href="#" className="inline-block">
                ACTIVE
              </a>
            </li>

            <li className="product subscribed text-center">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check"
                className="svg-inline--fa fa-check fa-w-16"
                role="img"
                width=" 14"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <span className="block">Holistic QIP</span>
              <a
                href="https://app.holisticqip.com.au/"
                className="inline-block"
                target="_blank"
                rel="noreferrer"
              >
                VISIT
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="footerBottom">
        <div className="footerRow flex items-center justify-between">
          <div
            className={`footerMenu ${
              isUserLoggedIn() ? "visible" : "invisible"
            }`}
          >
            <ul className="flex items-center">
              <li>
                <a href="/portal/monthly-focus" className="footerMenuText">
                  Monthly Focus
                </a>
              </li>
              <li>
                <a href="/portal/nqa-documents" className="footerMenuText">
                  NQA Documents
                </a>
              </li>
              <li>
                <a href="/portal/newsletters" className="footerMenuText">
                  Newsletters
                </a>
              </li>
              <li>
                <a href="/portal/links" className="footerMenuText">
                  Links
                </a>
              </li>
              <li>
                <a href="/portal/reports/main" className="footerMenuText">
                  Reports
                </a>
              </li>
            </ul>
          </div>

          <div className="footerCopyright">
            <span className="block">
              Copyright © 2021 Holistic Management And Consultancy. All Rights
              Reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
