import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";
import { userLogout } from "../../services/auth";

import { AuthObject } from "../../types";
import { IAuthUser } from "../../types";

export interface AuthModel {
  authObject?: AuthObject;
  logout: Thunk<AuthModel>;
  clearSession: Action<AuthModel>;
  setAuthObject: Action<AuthModel, AuthObject>;
  setAuthUser: Action<AuthModel, IAuthUser>;
}

const authStore: AuthModel = {
  setAuthObject: action((state, payload) => {
    localStorage.setItem("inteli_token", JSON.stringify(payload));
    state.authObject = payload;
  }),

  setAuthUser: action((state, payload) => {
    localStorage.setItem("authUser", JSON.stringify(payload));
  }),

  logout: thunk(async (actions) => {
    localStorage.removeItem("inteli_token");
    actions.clearSession();
  }),
  clearSession: action((state) => {
    localStorage.clear();
  }),
};

export default authStore;
