import * as React from "react";

import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./ConfirmRedirectModal.module.css";
import { FaSpinner, FaTimes } from "react-icons/fa";
import GreenButton from "../../GreenButton";

const { useState } = React;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#ffffff",
    width: "500px",
    height: "auto",
    padding: theme.spacing(2, 4, 3),
  },
}));

function ConfirmRedirectModal({
  message,
  buttonText,
  visibility,
  onOpenModal,
  onCloseModal,
  onConfirm,
}) {
  const classes = useStyles();
  return (
    <Modal
      className={`${classes.modal}`}
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      closeOnDimmerClick={false}
      onClose={onCloseModal}
    >
      <>
        <div className={`relative ${classes.paper} ${styles.modalContainer} `}>
          <div
            className={`${styles.closeButton} flex justify-center items-center cursor-pointer`}
            onClick={() => {
              onCloseModal();
            }}
          >
            {" "}
            <div className={`flex justify-center items-center text-2xl `}>
              <FaTimes />
            </div>{" "}
          </div>
          <text className={`${styles.headerText} px-3 mt-4 mb-4`}>
            {message}
          </text>

          <div className={`${styles.buttonGroup} mb-3`}>
            <GreenButton buttonText={buttonText} onClick={onConfirm} />

            {/* <SkipButton onClick={onCloseModal} buttonText="No" /> */}
          </div>
        </div>
      </>
    </Modal>
  );
}

export default ConfirmRedirectModal;
