import * as React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../hooks';

import { generatePlainText, generateHash } from '../../services/crypto';
import { setUserToken } from '../../services/auth';
import { FaSpinner } from 'react-icons/fa';
import { getAuthUser, getUserDataServices } from '../../api/request';
import { isEmpty } from 'lodash';

const { useEffect } = React;

export default function HubAppRedirect() {
	const match = useRouteMatch();

	const url = new URL(window.location.href);

	const userId = parseInt(url.searchParams.get('userId'));
	const centerId = parseInt(url.searchParams.get('centerId'));
	const stripeId = url.searchParams.get('stripeId');
	const cipherToken = decodeURIComponent(url.searchParams.get('accessToken'));
	const tokenHash = decodeURIComponent(url.searchParams.get('tokenHash'));
	const cipherAuth = decodeURIComponent(url.searchParams.get('auth'));
	const authHash = decodeURIComponent(url.searchParams.get('authHash'));

	const setSelectedService = useStoreActions(
		actions => actions.appStore.setSelectedService
	);
	const setAuthObject = useStoreActions(
		actions => actions.authStore.setAuthObject
	);

	const setAuthUser = useStoreActions(actions => actions.authStore.setAuthUser);

	const initDetails = async () => {
		localStorage.clear();
		const accessToken = generatePlainText(cipherToken);
		const authUser = generatePlainText(cipherAuth);

		const tHash = generateHash(accessToken);
		const aHash = generateHash(authUser);

		if (tHash !== tokenHash || aHash !== authHash) {
			window.location = '/auth';
			// return <Redirect to={`/qip`} />;
		} else {
			setUserToken(accessToken, () => {});

			setAuthUser(JSON.parse(authUser));

			if (stripeId === 'empty' || stripeId === 'null') {
				setAuthObject({
					userId: Number(userId),
					stripeId: null,
					accessToken: accessToken,
				});
			} else {
				setAuthObject({
					userId: Number(userId),
					stripeId: stripeId,
					accessToken: accessToken,
				});
			}

			if (centerId > 0) {
				const allServices = await getUserDataServices();
				if (!isEmpty(allServices)) {
					setSelectedService(
						allServices.filter(service => {
							return service.id === centerId;
						})[0]
					);
				}
			}
		}
	};

	useEffect(() => {
		initDetails().then(() => {
			window.location = '/';
		});
	}, [userId, centerId]);

	return (
		<div
			className={`w-full h-screen flex flex-col justify-center items-center`}
		>
			<FaSpinner className={`animate-spin text-qipGreen font-bold text-6xl`} />

			<div>Please wait...</div>
		</div>
	);
}
