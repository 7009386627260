import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import portalLogo from "../../static/images/HMCPortal_Logo.png";
import { Routes } from "../../context/App";
import BookAMentorSession from "../../components/Modals/BookAMentorSession";
import Search from "../../components/Modals/Search";
import ChooseService from "../Modals/ChooseService";
import { useStoreActions, useStoreState } from "../../hooks";
import { getUserLoggedIn, isUserLoggedIn } from "../../services/auth";
import LogoutModal from "../Modals/LogoutModal";
import {
  FaBuilding,
  FaCaretDown,
  FaCog,
  FaEnvelope,
  FaHeart,
  FaUsers,
} from "react-icons/fa";
import "./header.css";
import { isEmpty } from "lodash";

interface IRoute {
  route:
    | typeof Routes.DASHBOARD
    | typeof Routes.LINKS
    | typeof Routes.MONTHLYFOCUS
    | typeof Routes.NEWSLETTERS
    | typeof Routes.NQADOCUMENT
    | typeof Routes.NQADOCUMENTS
    | typeof Routes.REPORTS
    | typeof Routes.BOOKAMENTORSESSION
    | typeof Routes.SERVICEDETAILS
  | typeof Routes.SEARCHRESULTS
    | typeof Routes.RESOURCES;
}

const Header: React.FC<IRoute> = (props): JSX.Element => {
  const [openBookMentor, setOpenBookMentor] = useState<boolean>(false);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openService, setOpenService] = useState<boolean>(false);

  const [openMobileMenu, setMobileMenu] = useState("");

  const { route } = props;
  const history = useHistory();

  const [openReportsMenu, setOpenReportsMenu] = useState<boolean>(false);

  const services = useStoreState((state) => state.appStore.services);

  const authUser = getUserLoggedIn();

  const [logoutModalVisibility, setLogoutModalVisibility] = useState(false);

  const selectedService = useStoreState(
    (state) => state.appStore.selectedService
  );

  const isNotTrial = !(services?.length === 1 && !services[0].hasSub);

  return (
    <div className="header">
      {logoutModalVisibility && (
        <LogoutModal
          visibility={logoutModalVisibility}
          onOpenModal={() => setLogoutModalVisibility(true)}
          onCloseModal={() => setLogoutModalVisibility(false)}
        />
      )}
      {openService && (
        <ChooseService
          services={services}
          isOpen={openService}
          setIsOpen={setOpenService}
        />
      )}
      {openSearch && <Search isOpen={openSearch} setIsOpen={setOpenSearch} />}
      {openBookMentor && (
        <BookAMentorSession
          isOpen={openBookMentor}
          setIsOpen={setOpenBookMentor}
        />
      )}
      <div className={`topHeader ${isUserLoggedIn() ? "" : "hidden"}`}>
        <div className="headerRow flex items-center justify-between">
          <div className="topHeaderLeft">
            <ul className="topHeaderMenu flex items-center">
              <li
                onClick={() => {
                  history.push(`/edit-service-details`);
                }}
                className={`cursor-pointer`}
              >
                <a className="flex items-center">
                  <FaBuilding className={`mr-2`} />
                  Service Details
                </a>
              </li>

              {/*<li className={``}>
                <a
                  href="https://dev.holisticmanagementhub.com.au/Messages/Messages.php"
                  className="flex items-center"
                  target="_blank"
                >
                  <FaEnvelope className={`mr-2`} />
                  Messages
                </a>
              </li>*/}
              {isUserLoggedIn() &&
                authUser.user_type.toLocaleLowerCase() !== "user" && (
                  <li
                    onClick={() => {
                      window.open(
                        ` ${process.env.REACT_APP_HUB_URL}subscription`
                      );
                    }}
                    className={`cursor-pointer`}
                  >
                    <a className="flex items-center ">
                      <FaCog className={`mr-2`} />
                      Subscriptions
                    </a>
                  </li>
                )}
              <li
                onClick={() => {
                  window.open(` ${process.env.REACT_APP_HUB_URL}user-accounts`);
                }}
                className={`cursor-pointer`}
              >
                <a className="flex items-center">
                  <FaUsers className={`mr-2`} />
                  Users
                </a>
              </li>

              <li>
                <a
                  href="https://holisticmanagement.net.au/get-help/"
                  className="flex items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaHeart className={`mr-2`} />
                  Help
                </a>
              </li>
            </ul>
          </div>

          <div className="topHeaderRight">
            {!isEmpty(services) && isNotTrial && (
              <div className="services">
                <span className="selectedService">
                  {selectedService ? selectedService.name : ""}
                </span>
                <span
                  onClick={() => {
                    setOpenService(true);
                  }}
                  data-toggle="modal"
                  data-target="#servicesListModal"
                  className="changeService flex items-center"
                >
                  Change{" "}
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    className="svg-inline--fa fa-caret-down fa-w-10"
                    role="img"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    ></path>
                  </svg>
                </span>
              </div>
            )}

            <div className="LogoutBtn">
              <button onClick={() => setLogoutModalVisibility(true)}>
                Logout
              </button>
            </div>
          </div>

          <div className="searchBar flex items-center justify-center">
            <div className="search flex items-center justify-between">
              <input
                type="text"
                value=""
                name="search"
                id="s"
                placeholder="| Type your search term here & hit enter"
              />
              <button
                type="submit"
                className="flex items-center justify-center"
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
            <div className="closeSearch">
              <i className="fa fa-times"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="bottomHeader">
        <div className="headerRow flex items-center justify-between">
          <div className="logo">
            <a href="/portal/dashboard">
              <img
                className={`w-52 h-auto`}
                src={portalLogo}
                alt="HM&C Portal logo"
              />
            </a>
          </div>

          <div
            className={`headerMenu ${
              isUserLoggedIn() ? "visible" : "invisible"
            }`}
          >
            <ul className="mainHeaderMenu flex items-center">
              <li
                className={`menuItem ${
                  route === Routes.DASHBOARD || route === Routes.SERVICEDETAILS
                    ? "bg-portalGreen "
                    : ""
                }`}
              >
                <a
                  onClick={() => history.push(`/dashboard`)}
                  className={`menuItemText cursor-pointer ${
                    route === Routes.DASHBOARD ||
                    route === Routes.SERVICEDETAILS
                      ? "text-white"
                      : ""
                  } `}
                >
                  Dashboard
                </a>
              </li>
              <li
                className={`menuItem ${
                  route === Routes.MONTHLYFOCUS
                    ? "bg-portalGreen text-white"
                    : ""
                }`}
              >
                <a
                  onClick={() => history.push(`/monthly-focus`)}
                  className={`menuItemText cursor-pointer ${
                    route === Routes.MONTHLYFOCUS ? "text-white" : ""
                  } `}
                >
                  Monthly focus
                </a>
              </li>
              <li
                className={`menuItem hasDropdown ${
                  route === Routes.NQADOCUMENTS || route === Routes.NQADOCUMENT
                    ? "bg-portalGreen text-white"
                    : ""
                }`}
              >
                <a
                  className={`menuItemText cursor-pointer ${
                    route === Routes.NQADOCUMENTS ||
                    route === Routes.NQADOCUMENT
                      ? "text-white"
                      : ""
                  } hover:text-portalGreen`}
                >
                  NQA Documents
                </a>
                <ul className="subMenu">
                  <li
                    onClick={() => history.push(`/nqa-documents`)}
                    className="subMenuItem"
                  >
                    <a className="subMenuItemText cursor-pointer ">
                      View Documents
                    </a>
                  </li>
                  <li
                    onClick={() => history.push(`/nqa-document`)}
                    className="subMenuItem"
                  >
                    <a className="subMenuItemText cursor-pointer ">
                      Add your own
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className={`menuItem ${
                  route === Routes.NEWSLETTERS
                    ? "bg-portalGreen text-white"
                    : ""
                }`}
              >
                <a
                  onClick={() => history.push(`/newsletters`)}
                  className={`menuItemText cursor-pointer ${
                    route === Routes.NEWSLETTERS ? "text-white" : ""
                  } `}
                >
                  Newsletters
                </a>
              </li>
              <li
                className={`menuItem ${
                  route === Routes.LINKS ? "bg-portalGreen text-white" : ""
                }`}
              >
                <a
                  onClick={() => history.push(`/links`)}
                  className={`menuItemText cursor-pointer ${
                    route === Routes.LINKS ? "text-white" : ""
                  } `}
                >
                  Links
                </a>
              </li>
              <li
                // onClick={() => setOpenReportsMenu(!openReportsMenu)}
                className={`menuItem hasDropdown ${
                  route === Routes.REPORTS ? "bg-portalGreen text-white" : ""
                }`}
              >
                <a
                  className={`menuItemText cursor-pointer ${
                    route === Routes.REPORTS ? "text-white" : ""
                  } hover:text-portalGreen`}
                >
                  Reports
                </a>
                <ul
                  className={
                    isUserLoggedIn() &&
                    services.some(
                      (service) =>
                        service.product
                          ?.toLocaleLowerCase()
                          .includes("trial") ||
                        service.product
                          ?.toLocaleLowerCase()
                          .includes("mentoring")
                    ) &&
                    authUser.user_type.toLocaleLowerCase() !== "user"
                      ? "subMenu"
                      : "subMenu1"
                  }
                >
                  <li
                    onClick={() =>
                      history.push(`/reports/main`, {
                        slug: "reports",
                      })
                    }
                    className="subMenuItem"
                  >
                    <a className="subMenuItemText cursor-pointer ">Reports</a>
                  </li>
                  {isUserLoggedIn() &&
                    authUser.user_type.toLocaleLowerCase() !== "user" && (
                      <li
                        onClick={() =>
                          history.push(`/reports/ap`, {
                            slug: "reports",
                          })
                        }
                        className="subMenuItem"
                      >
                        <a className="subMenuItemText cursor-pointer">
                          AP Reports
                        </a>
                      </li>
                    )}
                  <li
                    onClick={() =>
                      history.push(`/reports/mentoring`, {
                        slug: "reports",
                      })
                    }
                    className="subMenuItem"
                  >
                    <a className="subMenuItemText cursor-pointer">
                      Mentoring Reports
                    </a>
                  </li>
                  <li
                    onClick={() =>
                      history.push(`/reports/fom`, {
                        slug: "reports",
                      })
                    }
                    className="subMenuItem"
                  >
                    <a className="subMenuItemText cursor-pointer">
                      FOM Reports
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className={`menuItem ${
                  route === Routes.RESOURCES
                    ? "bg-portalGreen text-white"
                    : ""
                }`}
              >
                <a
                  onClick={() => history.push(`/${Routes.RESOURCES}`)}
                  className={`menuItemText cursor-pointer ${
                    route === Routes.RESOURCES ? "text-white" : ""
                  } `}
                >
                  Resources
                </a>
              </li>
              {isUserLoggedIn() &&
                services.some(
                  (service) =>
                    (service.product?.toLocaleLowerCase().includes("trial") &&
                      service.diffTime &&
                      service.diffTime > -7) ||
                    service.product?.toLocaleLowerCase().includes("mentoring")
                ) &&
                authUser.user_type.toLocaleLowerCase() !== "user" && (
                  <li
                    onClick={() => setOpenBookMentor(true)}
                    className={`menuItem`}
                  >
                    <a
                      className={`menuItemText cursor-pointer ${
                        route === Routes.BOOKAMENTORSESSION ? "text-white" : ""
                      } `}
                      data-toggle="modal"
                      data-target="#bookMentorSessionModal"
                    >
                      Book a Mentor Session
                    </a>
                  </li>
                )}
            </ul>

            <div
              onClick={() => {
                setOpenSearch(true);
              }}
              className="searchBtn"
            >
              <button>
                <svg
                  font-size="13"
                  width="1em"
                  height="1em"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="search"
                  className="svg-inline--fa fa-search fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                  ></path>
                </svg>
              </button>
            </div>

            <div className="mobileMenu">
              <button
                onClick={() => setOpenMenu(!openMenu)}
                className="menuBars flex items-center justify-center"
              >
                <div className="bars"></div>
              </button>
              <ul
                className={`mainHeaderMobileMenu ${
                  openMenu ? "opacity-100 slideDown" : "hidden "
                } z-50`}
              >
                <li
                  className={`menuItem  ${
                    route === Routes.DASHBOARD ||
                    route === Routes.SERVICEDETAILS
                      ? "active "
                      : ""
                  } `}
                >
                  <a
                    onClick={() => history.push(`/dashboard`)}
                    className="menuItemText cursor-pointer "
                  >
                    Dashboard
                  </a>
                </li>
                <li
                  className={`menuItem  ${
                    route === Routes.MONTHLYFOCUS ? "active " : ""
                  } `}
                >
                  <a
                    onClick={() => history.push(`/monthly-focus`)}
                    className="menuItemText cursor-pointer cursor-pointer"
                  >
                    Monthly focus
                  </a>
                </li>
                <li
                  onClick={() =>
                    setMobileMenu(openMobileMenu === "nqa" ? "" : "nqa")
                  }
                  className={`menuItem hasDropdown  ${
                    route === Routes.NQADOCUMENTS ||
                    route === Routes.NQADOCUMENT
                      ? "active "
                      : ""
                  } `}
                >
                  <a className="menuItemText cursor-pointer">
                    NQA Documents
                    <FaCaretDown size={15} />
                  </a>
                  <ul
                    className={`${openMobileMenu === "nqa" ? "" : "hidden"} `}
                  >
                    <li
                      onClick={() => history.push(`/nqa-documents`)}
                      className="subMenuItem"
                    >
                      <a className="subMenuItemText cursor-pointer">
                        View Documents
                      </a>
                    </li>
                    <li
                      onClick={() => history.push(`/nqa-document`)}
                      className="subMenuItem"
                    >
                      <a className="subMenuItemText cursor-pointer">
                        Add your own
                      </a>
                    </li>
                  </ul>
                </li>

                <li
                  className={`menuItem  ${
                    route === Routes.NEWSLETTERS ? "active " : ""
                  } `}
                >
                  <a
                    onClick={() => history.push(`/newsletters`)}
                    className="menuItemText cursor-pointer"
                  >
                    Newsletters
                  </a>
                </li>
                <li
                  className={`menuItem  ${
                    route === Routes.LINKS ? "active " : ""
                  } `}
                >
                  <a
                    onClick={() => history.push(`/links`)}
                    className="menuItemText cursor-pointer"
                  >
                    Links
                  </a>
                </li>
                <li
                  onClick={() =>
                    setMobileMenu(openMobileMenu === "reports" ? "" : "reports")
                  }
                  className={`menuItem hasDropdown  ${
                    route === Routes.REPORTS ? "active " : ""
                  } `}
                >
                  <a className="menuItemText cursor-pointer">
                    Reports
                    <svg
                      font-size="15"
                      width="1em"
                      height="1em"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="caret-down"
                      className="svg-inline--fa fa-caret-down fa-w-10"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                      ></path>
                    </svg>
                  </a>
                  <ul
                    className={`${
                      openMobileMenu === "reports" ? "" : "hidden"
                    } `}
                  >
                    <li
                      onClick={() =>
                        history.push(`/reports/main`, {
                          slug: "reports",
                        })
                      }
                      className="subMenuItem"
                    >
                      <a className="subMenuItemText cursor-pointer">Reports</a>
                    </li>
                    <li
                      onClick={() =>
                        history.push(`/reports/ap`, {
                          slug: "reports",
                        })
                      }
                      className="subMenuItem"
                    >
                      <a className="subMenuItemText cursor-pointer">
                        AP Reports
                      </a>
                    </li>
                    <li
                      onClick={() =>
                        history.push(`/reports/mentoring`, {
                          slug: "reports",
                        })
                      }
                      className="subMenuItem"
                    >
                      <a className="subMenuItemText cursor-pointer">
                        Mentoring Reports
                      </a>
                    </li>
                    <li
                      onClick={() =>
                        history.push(`/reports/fom`, {
                          slug: "reports",
                        })
                      }
                      className="subMenuItem"
                    >
                      <a className="subMenuItemText cursor-pointer">
                        FOM Reports
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  className={`menuItem  ${
                    route === Routes.RESOURCES ? "active " : ""
                  } `}
                >
                  <a
                    onClick={() => history.push(`/${Routes.RESOURCES}`)}
                    className="menuItemText cursor-pointer"
                  >
                    Resources
                  </a>
                </li>
                
                <li
                  onClick={() => setOpenBookMentor(true)}
                  className={`menuItem  ${
                    route === Routes.BOOKAMENTORSESSION ? "active " : ""
                  } `}
                >
                  <a className="menuItemText cursor-pointer">
                    Book a Mentor Session
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
