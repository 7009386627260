import React, { Suspense } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Routes } from "../../context/App";
import { FaSpinner } from "react-icons/fa";
import ViewerInfoBar from "../ViewerInfoBar/ViewerInfoBar";
import { getPortalViewer } from "../../services/auth";
import { isEmpty } from "lodash";

interface LayoutProps {
  Children: React.ElementType;
  route:
    | typeof Routes.DASHBOARD
    | typeof Routes.LINKS
    | typeof Routes.MONTHLYFOCUS
    | typeof Routes.NEWSLETTERS
    | typeof Routes.NQADOCUMENT
    | typeof Routes.NQADOCUMENTS
    | typeof Routes.REPORTS
    | typeof Routes.BOOKAMENTORSESSION
    | typeof Routes.SERVICEDETAILS
    | typeof Routes.SEARCHRESULTS
    | typeof Routes.RESOURCES;
}

const Layout: React.FC<LayoutProps> = (props): JSX.Element => {
  const { Children, route } = props;
  return (
    <>
      <Header route={route} />
      {!isEmpty(getPortalViewer()) && <ViewerInfoBar />}
      <Suspense
        fallback={
          <div
            className={`w-screen h-screen flex flex-col justify-center items-center `}
          >
            <FaSpinner className={`text-6xl text-qipGreen animate-spin`} />{" "}
            <span className={`mt-2`}>Loading...</span>{" "}
          </div>
        }
      >
        <Children />
      </Suspense>
      <Footer />
    </>
  );
};

export default Layout;
