import { createStore } from "easy-peasy";
import storeModel from "./models";

const mainStore = createStore(
  storeModel,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export default mainStore;
