import React from "react";
import styles from "./ViewerInfoBar.module.css";
import { FaInfoCircle, FaSignOutAlt, FaTimes } from "react-icons/fa";
import LogoutModal from "../../components/Modals/LogoutModal/LogoutModal";
import { useStoreActions, useStoreState } from "../../hooks";
import { getPortalViewer, getUserLoggedIn } from "../../services/auth";
import { isEmpty } from "lodash";

const { useState } = React;

const ViewerInfoBar = () => {
  const [showLogout, setShowLogout] = useState(false);

  const selectedService = useStoreState(
    (state) => state.appStore.selectedService
  );

  const portalViewer = getPortalViewer();

  const authUser = getUserLoggedIn();

  return (
    <div
      className={`${
        styles.mainContainer
      } flex justify-center items-center px-4  ${
        isEmpty(getPortalViewer()) ? "hidden" : ""
      }`}
    >
      {showLogout && (
        <LogoutModal
          visibility={showLogout}
          onOpenModal={() => setShowLogout(true)}
          onCloseModal={() => setShowLogout(false)}
        />
      )}
      <div className={`${styles.container} flex justify-between items-center`}>
        <div className={`flex justify-center items-center`}>
          <FaInfoCircle className={`${styles.infoIcon} mx-2`} />
          <div className={styles.infoText}>
            {" "}
            You are currently viewing as{" "}
            {!isEmpty(authUser) ? authUser.name : ""}, Administrator of{" "}
            {!isEmpty(selectedService) ? selectedService.name : ""}
          </div>
        </div>
        <div className={`flex justify-center items-center`}>
          <div
            onClick={() => setShowLogout(true)}
            className={`${styles.logoutButton} hover:opacity-90 flex justify-center items-center`}
          >
            <FaSignOutAlt className={styles.buttonIcon} />{" "}
            <span className={`${styles.buttonText} ml-2`}> Logout</span>
          </div>
          <div
            onClick={() => {
              window.close();
            }}
            className={`${styles.closeButton} hover:opacity-90 flex justify-center items-center ml-3`}
          >
            <FaTimes className={styles.buttonIcon} />{" "}
            <span className={`${styles.buttonText} ml-2`}>Close</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewerInfoBar;
