import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Search.module.css";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface ISearchProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): any;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#ffffff",
    width: "70%",
    padding: theme.spacing(2, 4, 3),
  },
}));

const Search: React.FC<ISearchProps> = (props): JSX.Element => {
  const [searchKey, setSearchKey] = useState<string>("");
  const { isOpen, setIsOpen } = props;
  const classes = useStyles();

  const history = useHistory();

  return (
    <Modal className={`${classes.modal} outline-none`} open={isOpen}>
      <div
        className={`${classes.paper} outline-none flex justify-center items-center relative py-14`}
      >
        <div
          className={`${styles.inputContainer}  flex justify-start items-center py-1 pr-1`}
        >
          <input
            onChange={(e) => {
              setSearchKey(e.target.value);
            }}
            className={`${styles.inputSearch} outline-none h-full px-3 flex-1`}
            placeholder={`Type your search term here & hit enter`}
          />

          <div
            onClick={() => {
              history.push(`/search-results/${searchKey}`);
              setIsOpen(false);
            }}
            className={`${styles.searchContainer} cursor-pointer flex justify-center items-center`}
          >
            <FaSearch className={`${styles.search} `} />
          </div>
        </div>
        <div
          onClick={() => setIsOpen(false)}
          className={`${styles.cancelContainer} flex justify-center items-center cursor-pointer ml-2`}
        >
          <FaTimes className={` `} />
        </div>
      </div>
    </Modal>
  );
};

export default Search;
