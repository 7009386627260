import styles from "./MinimumWidthWarning.module.css";
import responsive from "../../static/images/responsive.png";

export default function MinimumWidthWarning() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.cardContainer}>
        <div className={styles.infoIcon}>
          <img src={responsive} alt="warning_responsive" />
        </div>
        <text className={styles.infoText}>
          The Holistic Management Portal does not support a screen resolution
          below 768px. Please open the app on a bigger screen - tablet, laptop
          or desktop computer.
        </text>
      </div>
    </div>
  );
}
