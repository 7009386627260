import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import MinimumWidthWarning from "./components/MinimumWidthWarning";
import useWindowDimensions from "./hooks/useWindowDimensions";
import LoggedInPages from "./routes/LoggedInPages";
import LoggedOutPages from "./routes/LoggedOutPages";
import HubAppRedirect from "./routes/HubAppRedirect/HubAppRedirect";
import PortalAdminRedirect from "./routes/PortalAdminRedirect";
import { isUserLoggedIn } from "./services/auth";
import { isEmpty } from "lodash";

const App: React.FC<any> = (): JSX.Element => {
  const { width } = useWindowDimensions();

  return (
    <Router>
      {width < 746 ? (
        <MinimumWidthWarning />
      ) : (
        <Switch>
          <Route
            path={`/view-portal-user-service`}
            render={() => <PortalAdminRedirect />}
          />

          <Route
            path={`/hub-app-launch-redirect`}
            render={() => {
              return <HubAppRedirect />;
            }}
          />
          <Route
            path={`/`}
            render={() => {
              return isUserLoggedIn() ? <LoggedInPages /> : <LoggedOutPages />;
            }}
          ></Route>
        </Switch>
      )}
    </Router>
  );
};

export default App;
