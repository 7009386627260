import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";
import {
  INewsletter,
  INqaDocuments,
  IFOMDocument,
  IService,
  IReport,
} from "../../types";
import { fetchReports, getDocumentByServiceId } from "../../api/request";

export interface AppModel {
  newsletters: INewsletter[] | [];
  nqadocuments: INqaDocuments[] | [];
  links: [];
  reports: IReport[] | [];
  setReports: Action<AppModel, IReport[]>;
  apreports: IReport[] | [];
  setApReports: Action<AppModel, IReport[]>;
  mentoringReports: IReport[] | [];
  fomReports: IReport[] | [];
  setMentoringReports: Action<AppModel, IReport[]>;
  setFomReports: Action<AppModel, IReport[]>;
  setNewsletters: Action<AppModel, INewsletter[]>;
  fetchReports: Thunk<AppModel, { serviceId?: number; serviceType: number }>;
  fetchApReports: Thunk<AppModel, { serviceId?: number; serviceType: number }>;
  fetchMentoringReports: Thunk<
    AppModel,
    { serviceId?: number; serviceType: number }
  >;
  fetchFomReports: Thunk<AppModel, { serviceId?: number; serviceType: number }>;
  setNqadocuments: Action<AppModel, INqaDocuments[]>;
  setLinks: Action<AppModel, []>;
  fomDocuments: IFOMDocument[] | [];
  setFomDocuments: Action<AppModel, IFOMDocument[]>;
  services: IService[] | [];
  docsById: INqaDocuments[] | [];
  setDocsById: Action<AppModel, INqaDocuments[]>;
  setServices: Thunk<AppModel, IService[]>;
  selectedService?: IService;
  setSelectedService: Action<AppModel, IService>;
  fetchDocsById: Thunk<AppModel, { serviceId?: number }>;
  setAllServices: Action<AppModel, IService[]>;
}

const appStore: AppModel = {
  docsById: [],
  setDocsById: action((state, payload) => {
    state.docsById = payload;
  }),
  fetchDocsById: thunk(async (actions, payload) => {
    try {
      const response = await getDocumentByServiceId(payload.serviceId);

      actions.setDocsById(response);
    } catch (error) {
      console.log(error);
    }
  }),
  newsletters: [],
  setNewsletters: action((state, payload) => {
    state.newsletters = payload;
  }),
  nqadocuments: [],
  setNqadocuments: action((state, payload) => {
    state.nqadocuments = payload;
  }),
  links: [],
  setLinks: action((state, payload) => {
    state.links = payload;
  }),
  fetchReports: thunk(async (actions, payload) => {
    try {
      const responseReports = await fetchReports(
        payload.serviceType,
        payload.serviceId
      );

      actions.setReports(responseReports);
    } catch (error) {
      console.log(error);
    }
  }),
  fetchApReports: thunk(async (actions, payload) => {
    try {
      const responseApReports = await fetchReports(
        payload.serviceType,
        payload.serviceId
      );

      actions.setApReports(responseApReports);
    } catch (error) {
      console.log(error);
    }
  }),
  fetchMentoringReports: thunk(async (actions, payload) => {
    try {
      const mentoringReports = await fetchReports(
        payload.serviceType,
        payload.serviceId
      );

      actions.setMentoringReports(mentoringReports);
    } catch (error) {
      console.log(error);
    }
  }),
  fetchFomReports: thunk(async (actions, payload) => {
    try {
      const fomReports = await fetchReports(
        payload.serviceType,
        payload.serviceId
      );

      actions.setFomReports(fomReports);
    } catch (error) {
      console.log(error);
    }
  }),
  reports: [],
  apreports: [],
  mentoringReports: [],
  fomReports: [],
  setMentoringReports: action((state, payload) => {
    state.mentoringReports = payload;
  }),
  setFomReports: action((state, payload) => {
    state.fomReports = payload;
  }),
  setReports: action((state, payload) => {
    state.reports = payload;
  }),
  setApReports: action((state, payload) => {
    state.apreports = payload;
  }),
  fomDocuments: [],
  setFomDocuments: action((state, payload) => {
    state.fomDocuments = payload;
  }),
  setSelectedService: action((state, payload) => {
    localStorage.setItem("selectedService", JSON.stringify(payload));

    state.selectedService = payload;
  }),
  services: [],
  setAllServices: action((state, payload) => {
    state.services = payload;
  }),
  setServices: thunk(async (actions, payload) => {
    actions.setAllServices(payload);
    const currentSelService = localStorage.getItem("selectedService");
    if (!currentSelService) {
      actions.setSelectedService(payload[0]);
      await actions.fetchDocsById({ serviceId: payload[0].id });
      await actions.fetchReports({ serviceType: 1, serviceId: payload[0].id });
      await actions.fetchApReports({
        serviceType: 2,
        serviceId: payload[0].id,
      });
      await actions.fetchMentoringReports({
        serviceType: 3,
        serviceId: payload[0].id,
      });
      await actions.fetchFomReports({
        serviceType: 6,
        serviceId: payload[0].id,
      });
    } else {
      const curService = JSON.parse(currentSelService);
      const selected = payload.filter((s) => s.id === curService.id)[0];

      actions.setSelectedService(selected);
      await actions.fetchDocsById({ serviceId: selected.id });
      await actions.fetchReports({ serviceType: 1, serviceId: selected.id });
      await actions.fetchApReports({ serviceType: 2, serviceId: selected.id });
      await actions.fetchMentoringReports({
        serviceType: 3,
        serviceId: selected.id,
      });
      await actions.fetchFomReports({
        serviceType: 6,
        serviceId: payload[0].id,
      });
    }
  }),
};

export default appStore;
