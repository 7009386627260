import { createContext, useContext } from "react";

export enum Routes {
  DASHBOARD = "dashboard",
  MONTHLYFOCUS = "montlyfocus",
  NQADOCUMENT = "nqadocument",
  NQADOCUMENTS = "nqadocuments",
  NEWSLETTERS = "newsletters",
  LINKS = "links",
  REPORTS = "reports",
  BOOKAMENTORSESSION = "bookamentorsession",
  SERVICEDETAILS = "servicedetails",
    SEARCHRESULTS = "searchresults",
  RESOURCES = "resources",
}

export type AppContextType = {
  route: Routes;
};

export const AppContext = createContext<AppContextType>({
  route: Routes.DASHBOARD,
});

export const useApp = () => useContext(AppContext);
