import React from "react";
import styles from "./LogoutRedirect.module.css";

const LogoutRedirect = () => {
  return (
    <div className={`flex justify-center items-center h-screen`}>
      <div
        className={`${styles.mainContainer} w-8/12 mb-10 py-32 px-5 flex flex-col justify-center items-center`}
      >
        <h2 className={styles.header}>
          You have successfully logged out of the Holistic Portal
        </h2>
        <h2 className={styles.subHeader}>
          To log back in, please visit the Holistic HUB
        </h2>
        <a
          onClick={() => {
            window.open(process.env.REACT_APP_HUB_URL);
          }}
          className={`${styles.buttonRedirect} cursor-pointer py-3 px-4 mt-8`}
        >
          Visit Holistic HUB
        </a>
      </div>
    </div>
  );
};

export default LogoutRedirect;
