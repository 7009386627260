import * as React from "react";

import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import SkipButton from "../../SkipButton";
import styles from "./LogoutModal.module.css";
import { useStoreActions } from "../../../hooks";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#ffffff",
    width: "700px",
    padding: theme.spacing(2, 6, 7),
  },
}));

function LogoutModal({ visibility, onOpenModal, onCloseModal }) {
  const logout = useStoreActions((actions) => actions.authStore.logout);

  const classes = useStyles();

  const onLogout = async () => {
    await logout();
    window.location.reload();
  };

  const onLogoutConfirm = async () => {
    await onLogout();
    window.location.reload();
    onCloseModal();
  };

  return (
    <Modal
      className={`${classes.modal} outline-none`}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      // className={`h-auto flex flex-col justify-center items-center`}
    >
      <div
        className={`${classes.paper}  outline-none flex flex-col justify-center items-center relative`}
      >
        <text className={`${styles.logoutText} mt-10`}>
          Do you wish to log out?
        </text>

        <SkipButton
          className={styles.saveButton}
          buttonText="Log out"
          onClick={onLogoutConfirm}
        />
      </div>
    </Modal>
  );
}

LogoutModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default LogoutModal;
