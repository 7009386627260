import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";

const Resources = lazy(() => import("../../pages/Resources"));

interface DashboardProps {
  route: Routes.RESOURCES;
  path: string;
  exact: boolean;
}

const ResourcesRoute: React.FC<DashboardProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute path={path} route={route} exact={exact} Component={Resources} />
  );
};

export default ResourcesRoute;
