import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";

const Reports = lazy(() => import("../../pages/Reports"));

interface DashboardProps {
  route: Routes.REPORTS;
  path: string;
  exact: boolean;
}

const ReportsRoute: React.FC<DashboardProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute path={path} route={route} exact={exact} Component={Reports} />
  );
};

export default ReportsRoute;
