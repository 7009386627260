import appStore, { AppModel } from "./appModel";
import authStore, { AuthModel } from "./authModel";

export interface StoreModel {
  appStore: AppModel;
  authStore: AuthModel;
}

const storeModel: StoreModel = {
  appStore,
  authStore,
};

export default storeModel;
