import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./BookAMentor.module.css";
import { FaFileAlt, FaTimes, FaSpinner } from "react-icons/fa";
import { requestMentoringSupport } from "../../../api/request";
import { IMentorIngPayload } from "../../../types";
import { getUserLoggedIn } from "../../../services/auth";
import { useStoreState } from "../../../hooks";

interface IBookProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): any;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#ffffff",
    width: "700px",
    padding: theme.spacing(2, 6, 7),
  },
}));

const BookAMentorSession: React.FC<IBookProps> = (props): JSX.Element => {
  const { isOpen, setIsOpen } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const authUser = getUserLoggedIn();

  const selectedService = useStoreState(
    (state) => state.appStore.selectedService
  );

  const [payload, setPayload] = useState<IMentorIngPayload>({
    name: authUser.name,
    email: authUser.email,
    phone: authUser.phone || "",
    service_name: selectedService?.name || "",
    describe_more: "",
    preferred_day: "",
    preferred_time: "",
  });
  const [fieldsChecked, setFieldsChecked] = useState<boolean>(false);

  const onSendRequest = async () => {
    setFieldsChecked(true);
    if (
      !payload.describe_more ||
      !payload.preferred_day ||
      !payload.preferred_time
    ) {
      return;
    }

    try {
      setLoading(true);
      const response = await requestMentoringSupport(payload);
      setLoading(false);
      setIsOpen(false);
    } catch (error) {
      setLoading(false);
      setIsOpen(false);
      console.log(error);
    }
  };

  const onSetPayload = (name: string, value: string) => {
    setPayload({
      ...payload,
      [name]: value,
    });
  };

  return (
    <Modal className={`${classes.modal} outline-none`} open={isOpen}>
      <div
        className={`${classes.paper} outline-none flex flex-col justify-center items-center relative`}
      >
        <div
          onClick={() => setIsOpen(false)}
          className={`${styles.closeIcon} cursor-pointer absolute top-0 right-0 flex justify-center items-center`}
        >
          <FaTimes />
        </div>
        <div
          className={`${styles.headerIcon} flex justify-center items-center rounded-full mt-12 mb-4`}
        >
          <FaFileAlt />{" "}
        </div>
        <div className={`${styles.headerText} mb-4`}>
          {`Get mentor support & guidance`}
        </div>
        <div className={`${styles.subHeaderText} mb-14`}>
          {`Our professional mentors will review your service and service practices. Please provide a note about your request, and any specific areas you need help and one of our mentors will contact you to arrange a convenient time for a mentor session.`}
        </div>
        <div className={`w-full flex flex-col justify-start mb-5`}>
          <label className={`${styles.label} mb-2`}>Describe more</label>
          <textarea
            name={`describe_more`}
            onChange={(e) => onSetPayload(e.target.name, e.target.value)}
            className={`${styles.describeInput} outline-none w-full p-3`}
            placeholder={`Write here`}
          />
          <div className={`text-red-400 italic py-1 px-1 h-4`}>
            {fieldsChecked && !payload.describe_more && (
              <> This field is required. </>
            )}
          </div>
        </div>
        <div className={`w-full flex justify-between items-center mb-7`}>
          <div className={`w-1/2 flex flex-col justify-start mr-3 `}>
            <label className={`${styles.label} mb-2`}>Preferred day</label>
            <input
              name={`preferred_day`}
              onChange={(e) => onSetPayload(e.target.name, e.target.value)}
              className={`${styles.preferredDay} outline-none w-full px-3`}
              placeholder={`Day`}
            />

            <div className={`text-red-400 italic py-1 px-1 h-4`}>
              {fieldsChecked && !payload.preferred_day && (
                <> This field is required. </>
              )}
            </div>
          </div>
          <div className={`w-1/2 flex flex-col justify-start ml-3 `}>
            <label className={`${styles.label} mb-2`}>Preferred time</label>
            <input
              name={`preferred_time`}
              onChange={(e) => onSetPayload(e.target.name, e.target.value)}
              className={`${styles.preferredTime} outline-none w-full px-3`}
              placeholder={`Time`}
            />
            <div className={`text-red-400 italic py-1 px-1 h-4`}>
              {fieldsChecked && !payload.preferred_time && (
                <> This field is required. </>
              )}
            </div>
          </div>
        </div>
        <button
          disabled={loading}
          onClick={() => onSendRequest()}
          className={`${styles.buttonRequest} px-4 cursor-pointer `}
        >
          {loading ? (
            <div className={`flex justify-center items-center`}>
              <FaSpinner className={`animate-spin`} />{" "}
              <span className={`ml-2`}>Please wait...</span>
            </div>
          ) : (
            <> REQUEST A MENTOR SESSION </>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default BookAMentorSession;
