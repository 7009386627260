import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";

const Newsletters = lazy(() => import("../../pages/Newsletters"));

interface DashboardProps {
  route: Routes.NEWSLETTERS;
  path: string;
  exact: boolean;
}

const NewslettersRoute: React.FC<DashboardProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute
      path={path}
      route={route}
      exact={exact}
      Component={Newsletters}
    />
  );
};

export default NewslettersRoute;
