import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./ChooseService.module.css";
import { FaDollarSign, FaTimes } from "react-icons/fa";
import { IService } from "../../../types";
import { useStoreActions, useStoreState } from "../../../hooks";
import ConfirmRedirectModal from "../ConfirmRedirectModal";

interface IChooseService {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): any;
  services: IService[] | [];
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#ffffff",
    width: "500px",
    height: "auto",
    padding: theme.spacing(2, 4, 3),
  },
}));

const ChooseService: React.FC<IChooseService> = (props): JSX.Element => {
  const { isOpen, setIsOpen, services } = props;
  const classes = useStyles();

  const hubURL = process.env.REACT_APP_HUB_URL;

  const [openRedirectModal, setOpenRedirectModal] = useState(false);

  const [redirectMessage, setRedirectMessage] = useState("");

  const [redirectPathUrl, setRedirectPathUrl] = useState("");

  const setSelectedService = useStoreActions(
    (actions) => actions.appStore.setSelectedService
  );

  const selectedService = useStoreState(
    (state) => state.appStore.selectedService
  );

  const onRedirect = () => {
    window.open(`${hubURL}${redirectPathUrl}`);
    setOpenRedirectModal(false);
    setIsOpen(false);
  };

  return (
    <Modal className={`${classes.modal} outline-none`} open={isOpen}>
      <div
        className={`${classes.paper} outline-none flex flex-col justify-start items-center relative py-10`}
      >
        {openRedirectModal && (
          <ConfirmRedirectModal
            message={redirectMessage}
            visibility={openRedirectModal}
            buttonText={`GO TO HOLISTIC HUB`}
            onCloseModal={() => setOpenRedirectModal(false)}
            onOpenModal={() => setOpenRedirectModal(true)}
            onConfirm={onRedirect}
          />
        )}
        <div
          onClick={() => setIsOpen(false)}
          className={`${styles.closeIcon} cursor-pointer absolute top-0 right-0 flex justify-center items-center`}
        >
          <FaTimes />
        </div>
        <text
          className={`${styles.headerText} my-4`}
        >{`Choose a service`}</text>
        <div className={`w-full overflow-y-auto `}>
          {services.map((service) => {
            return service.status === "active" ? (
              <div
                key={service.id}
                onClick={() => {
                  setSelectedService(service);

                  setIsOpen(false);
                  window.location.reload();
                }}
                className={` ${styles.serviceItem} ${service.id} ${
                  selectedService
                    ? selectedService.id === service.id
                      ? styles.selected
                      : ""
                    : ""
                }  px-3 flex justify-between items-center mb-2 cursor-pointer `}
              >
                {service.name}
              </div>
            ) : service.status === "overdue" ? (
              <div
                key={service.id}
                className={` ${styles.overdue} px-3  flex justify-between items-center mb-2  `}
              >
                {service.name} (Payment failed)
                <button
                  onClick={() => {
                    setRedirectMessage(
                      "You will redirected to Holistic HUB to update your details"
                    );
                    setRedirectPathUrl(`payment/failed`);

                    setOpenRedirectModal(true);
                  }}
                  // onClick={() => {
                  //   window.open(`${hubURL}payment/failed`);

                  //   setIsOpen(false);
                  // }}
                  className={`${styles.buttonOverdue} py-2 px-2 flex justify-center items-center cursor-pointer`}
                >
                  <span>PAYMENT FAILED</span>
                </button>
              </div>
            ) : service.status === "notoverdue" ? (
              <div
                key={service.id}
                className={` ${styles.overdue} px-3  flex justify-between items-center mb-2  `}
              >
                {service.name} (Payment failed)
                <button
                  onClick={() => {
                    setSelectedService(service);

                    setIsOpen(false);
                    window.location.reload();
                  }}
                  className={`${styles.buttonOverdue} py-2 px-2 flex justify-center items-center cursor-pointer`}
                >
                  <span>PAYMENT FAILED</span>
                </button>
              </div>
            ) : service.status === "resubscribe" ? (
              <div
                key={service.id}
                className={` ${styles.overdue} px-3  flex justify-between items-center mb-2  `}
              >
                {service.name} (Cancelled)
                <button
                  onClick={() => {
                    setRedirectMessage(
                      "You will be redirected to Holistic HUB to finish Resubscribing"
                    );
                    setRedirectPathUrl(`resubscribe`);

                    setOpenRedirectModal(true);
                  }}
                  className={`${styles.buttonOverdue} py-2 px-2 flex justify-center items-center cursor-pointer`}
                >
                  <FaDollarSign /> <span>RESUBSCRIBE</span>
                </button>
              </div>
            ) : service.status === "subscribe" ? (
              <div
                key={service.id}
                className={` ${styles.overdue} px-3  flex justify-between items-center mb-2  `}
              >
                {service.name} (Trial Ended)
                <button
                  onClick={() => {
                    setRedirectMessage(
                      "You will be redirected to Holistic HUB to finish subscribing"
                    );
                    setRedirectPathUrl(`resubscribe`);

                    setOpenRedirectModal(true);
                  }}
                  className={`${styles.buttonOverdue} py-2 px-2 flex justify-center items-center cursor-pointer`}
                >
                  <FaDollarSign /> <span>SUBSCRIBE</span>
                </button>
              </div>
            ) : (
              <> </>
            );
          })}
        </div>
        <div
          onClick={() => {
            setRedirectMessage(
              "You will redirected to Holistic HUB to add a new service"
            );
            setRedirectPathUrl(`plans/app_redirect-new_service`);

            setOpenRedirectModal(true);
          }}
          className={`${styles.addServiceButton} mt-5 px-4 py-4 cursor-pointer`}
        >
          ADD A NEW SERVICE
        </div>
      </div>
    </Modal>
  );
};

export default ChooseService;
