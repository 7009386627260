import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";

const Links = lazy(() => import("../../pages/Links"));

interface DashboardProps {
  route: typeof Routes.LINKS;
  path: string;
  exact: boolean;
}

const LinksRoute: React.FC<DashboardProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute path={path} route={route} exact={exact} Component={Links} />
  );
};

export default LinksRoute;
